<style scoped lang="less">
// .product-module {
// 	// height: 430px;
// 	display: flex;

// 	.menu {
// 		flex: 0 0 auto;

// 		height: 100%;
// 	}

// 	.list-box {
// 		flex: 1 1 auto;
// 		margin-left: 20px;
// 	}
// }
</style>
<template>
	<questionnaire-table @change="dataChange"></questionnaire-table>
</template>
<script>
// import brandTable from './modules/brand';
// import classifyTable from './modules/classify';
import questionnaireTable from './modules/questionnaire.vue';

export default {
	components: {
		// brandTable, //品牌
		// classifyTable, //分类
		questionnaireTable //在售商品
	},
	props: {
		visible: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			name: '1',
			show: false
		};
	},
	methods: {
		dataChange(record) {
			this.$emit('change', record);
		},
		menuChange(name) {
			this.$emit('change', null);
			this.name = name;
		},
		cancel(){
		    this.$emit('cancel');
		},
	},
	watch: {
		visible(value) {
			this.show = value;
		}
	},
	mounted() {},
	created() {
		// console.log('2222');
	},
	dispatch() {}
};
</script>
