<style scoped lang="less">
.brand-module{
    height: 100%;
    display: flex;
    flex-direction: column;

    .search{
        flex: 0 0 auto;
        margin-bottom: 10px;
    }
    .tab-box{
        flex: 1 1 auto;
        margin-bottom: 10px;
    }
    .pagination{
        flex: 0 0 auto;
    }
}
</style>
<template>
    <div class="brand-module">
        
        <div class="search">
        	<span style="padding-right: 10px;">关键字：</span>
            <el-input v-model="keyword" style="width:200px;margin-right:40px" size="mini" placeholder="优惠券名称"/>
            <el-button type="primary" size="mini" @click="pageIndex=1;getData();">搜索</el-button>
						<el-button size="mini" @click="getData();">刷新</el-button>
        </div>

        <div class="tab-box">
			<el-table :data="data" v-loading="loading" @row-click="handleRowClick" :row-key="getRowKey" size="mini">
				<el-table-column width="40px">
					<template slot-scope="scope">
						<el-checkbox v-model="scope.row.checked"></el-checkbox>
					</template>
				</el-table-column>
				<el-table-column prop="CouponName" label="优惠券" width="305px"></el-table-column>
				<el-table-column label="类型" width="100px">
					<template slot-scope="scope">
						<span v-if="scope.row.UseType===0">满减券</span>
						<span v-if="scope.row.UseType===1">打折券</span>
						<span v-if="scope.row.UseType===2">随机金额券</span>
					</template>
				</el-table-column>
				<el-table-column label="优惠内容" width="165px">
					<template slot-scope="scope">
						<span v-if="scope.row.FullMoney">满{{scope.row.FullMoney}}元,</span>
						<span v-else>无门槛,</span>
						<span v-if="scope.row.UseType===0">减{{scope.row.MinMoney}}元</span>
						<span v-if="scope.row.UseType===1">打{{scope.row.Discount/10}}折</span>
						<span v-if="scope.row.UseType===2">减{{scope.row.MinMoney}}~{{scope.row.MaxMoney}}元</span>
					</template>
				</el-table-column>
				<el-table-column label="适用商品" width="140px">
					<template slot-scope="scope">
						<span v-if="scope.row.ActivityRangeType===0">全部商品可用</span>
						<span v-else>部分商品可用</span>
					</template>
				</el-table-column>
				<el-table-column label="限领次数" width="100px">
					<template slot-scope="scope">
						<span v-if="scope.row.ReceiveLimit">限领{{scope.row.ReceiveLimit}}次</span>
						<span v-else>不限制</span>
					</template>
				</el-table-column>
				<el-table-column prop="RemainCount" label="剩余数量" width="100px"></el-table-column>
				
				<div slot="empty">暂无数据，<el-link type="primary" :href="url" target="_blank">点击此处前往添加</el-link></div>
			</el-table>
		</div>

        <div class="pagination">
            <el-pagination :current-page="pageIndex" :total="total" :page-size="pageSize" @current-change="pageChange"></el-pagination>
        </div>

    </div>
</template>
<script>

import {editSelectWheelSurf, editSelectCoupon} from '../../../api/pageManage.js';

export default {
    components: {
        
    },
    props: {
        
    },
    data () {
        return {
            keyword: '',
			state: null,
            pageIndex: 1,
            pageSize: 10,
            total: 0,
            data: [],
            loading: false,
			url: location.origin + '/index.html#/market/coupon/index',
			
			options: [{
			  value: null,
			  label: '全部'
			}, {
			  value: 0,
			  label: '未开始'
			}, {
			  value: 1,
			  label: '进行中'
			}],
        };
    },
    methods: {
        async getData(){
			this.loading = true;
            let data = {
            	Keywords:this.keyword,
            	Skip: (this.pageIndex - 1) * this.pageSize, 
            	Take: this.pageSize,
            }
            let result = await editSelectCoupon(data);
            this.total = result.Result.Total;
            this.data = (result.Result.Results || []).map(item=>{
				item.uid = this.guid();
				return item;
			});
			
			this.loading = false;
        },
        pageChange(value){
            this.pageIndex = value;
            this.getData();
        },
		getRowKey(row){
			return row.Id
		},
		handleRowClick(row, event, column){
			this.data = this.data.map(item=>{
				item.checked = item.Id == row.Id;
				return item;
			})
			this.$emit('change', {data:row, type:'coupon', text: row.CouponName})
		},
		guid() {
			var a = function() {
				return (65536 * (1 + Math.random()) | 0).toString(16).substring(1)
			};
			return 'a' + a() + a() + "-" + a() + "-" + a() + "-" + a() + "-" + a() + a() + a()
		}
    },
    watch: {
    },
    mounted () {
        
    },
    created () {
        this.getData();
    },
    dispatch () {
        
    }
};
</script>
