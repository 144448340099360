<style scoped lang="less">
	.brand-module{
    height: 100%;
    display: flex;
    flex-direction: column;

    .search{
        margin-bottom: 10px;
    }
    .tab-box{
        flex: 1 1 auto;
        margin-bottom: 10px;
    }
    .pagination{
        flex: 0 0 auto;
    }
}
.shop-name{
	display: flex;
}
.shop-name .img{
	flex: 0 0 auto;
}
.shop-name .name{
	flex: 1 1 auto;
	overflow: hidden;
	display: flex;
	align-items: center;
}
</style>
<template>
	<div class="brand-module">

		<div class="filter-container">
			<div class="filter-item">
				<span style="padding-right: 10px;">关键字：</span>
				<el-input v-model="keyword" style="width:250px;margin-right:10px" size="mini" placeholder="商品名称、编码" />
				
			</div>
			<div class="filter-item">
				<span style="padding-right: 10px;">销售类型: </span>
				<el-select v-model="saleType" style="width: 160px;margin-right: 10px;" clearable size="mini">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in saleSelect" :key="item.id" :label="item.type" :value="item.id">
					</el-option>
				</el-select>
			</div>
			
			<div class="filter-item">
				<span style="padding-right: 10px;">销售状态: </span>
				<el-select v-model="saleState" style="width: 160px;margin-right: 10px;" clearable size="mini">
					<el-option v-for="item in stateOptions" :key="item.id" :label="item.type" :value="item.id">
					</el-option>
				</el-select>
			</div>

			<div class="filter-item">
				<span style="padding-right: 10px;">商品分组: </span>
				<el-select v-model="goodsType" size="mini" placeholder="请选择" style="width: 160px;margin-right: 10px;" clearable
				 filterable>
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in productSortList" :key="item.Value" :label="item.Text" :value="item.Value">
					</el-option>
				</el-select>
			</div>
			
			
			<div class="filter-item">
				<span style="padding-right: 10px;">商品品牌: </span>
				<el-select v-model="brandType" size="mini" placeholder="请选择" style="width: 160px;" clearable filterable>
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in productList" :key="item.Value" :label="item.Text" :value="item.Value">
					</el-option>
				</el-select>
			</div>
			<el-button type="primary" size="mini" @click="pageIndex=1;getData();">搜索</el-button>
			<el-button size="mini" @click="getData();">刷新</el-button>
		</div>

		<div class="tab-box">
			<el-table :data="data" v-loading="loading" @row-click="handleRowClick" size="mini" row-key="getRowKey">
				<el-table-column width="40px">
					<template slot-scope="scope">
						<el-checkbox v-model="scope.row.checked"></el-checkbox>
					</template>
				</el-table-column>
				<el-table-column label="商品" width="500px">
					<template slot-scope="scope">
						<div class="shop-name" style="overflow: hidden;">
							<div class="img" style="width: 66px;height: 66px;">
								<img :src="imgUrl + scope.row.ImgUrl" style="display: block;width: 100%;" />
							</div>
							<div class="name" style="margin-left: 10px;">
								<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="销售类型">
					<template slot-scope="scope">
						<span v-if="scope.row.ProductType===0">普通商品</span>
						<span v-else>组合商品</span>
					</template>
				</el-table-column>
				<el-table-column label="价格">
					<template slot-scope="scope">
						&yen;{{scope.row.ProductPrice}}
					</template>
				</el-table-column>
				<el-table-column prop="Stock" label="库存"></el-table-column>
				<el-table-column prop="Stock" label="备注">
					<template slot-scope="scope">
						<div v-if="scope.row.SupplierMallId >0">分销商品</div>
						<div v-if="scope.row.ProductState == 3">已下架</div>
						<div v-if="scope.row.ProductState == 2">已售罄</div>
					</template>
				</el-table-column>
				<div slot="empty">暂无数据，<el-link type="primary" :href="url" target="_blank">点击此处前往添加</el-link></div>
			</el-table>
		</div>

		<div class="pagination">
			<el-pagination :current-page="pageIndex" :total="total" :page-size="pageSize" @current-change="pageChange"></el-pagination>
		</div>

	</div>
</template>
<script>
	import {
		editSelectProduct
	} from '../../../../api/pageManage.js';
	import {
		editSelectinit
	} from '@/api/goods';
	import config from '@/config/index'

	export default {
		components: {

		},
		props: {

		},
		data() {
			return {
				keyword: '',
				pageIndex: 1,
				pageSize: 10,
				total: 0,
				data: [],
				loading: false,
				imgUrl: config.IMG_BASE,
				url: location.origin + '/index.html#/goods/goodsList',
				
				goodsType:null,
				productSortList: [],
				brandType:null,
				productList: [],
				saleType:null, //销售类型
				saleSelect: [{
						id: 0,
						type: '单品销售'
					},
					{
						id: 1,
						type: '组合销售'
					},
				],
				saleState: null, 
				stateOptions: [{
						id: null,
						type: '全部'
					},
					{
						id: 1,
						type: '已上架'
					},
					{
						id: 2,
						type: '已售罄'
					},
					{
						id: 3,
						type: '已下架'
					},
				],
			};
		},
		methods: {
			
			//筛选数据
			async getInfor() {
				try {
					let result = await editSelectinit()
					// 品牌
					this.productSortList = result.Result.ProductGroupSelectList;
					this.productList = result.Result.ProductBrandSelectList;

				} catch (e) {
					console.log(e)
				} finally {

				}

			},
			async getData() {
				const params = {
					Keywords: this.keyword,
					ProductType:this.saleType,
					ProductState: this.saleState,
					ProductGroupId:this.goodsType,
					ProductBrandId:this.brandType,
					Skip: (this.pageIndex - 1) * this.pageSize,
					Take: this.pageSize
				}

				this.loading = true;

				const result = await editSelectProduct(params);

				this.data = result.Result.Results;
				this.total = result.Result.Total;
				this.loading = false;

			},
			pageChange(value) {
				this.pageIndex = value;
				this.getData();
			},
			getRowKey(row) {
				return row.Id
			},
			handleRowClick(row, event, column) {

				this.data = this.data.map(item => {
					item.checked = item.Id == row.Id;
					return item;
				})

				this.$emit('change', {
					data: row,
					type: 'product',
					text: row.Name
				})
			}
		},
		watch: {},
		mounted() {
		},
		created() {
			this.getInfor()
			this.getData();
		},
		dispatch() {

		}
	};
</script>
