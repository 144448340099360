<style scoped lang="less">
    // .product-module{
    //     margin: 30px;
    //     height: 365px;
    //     overflow: auto;
    // }
    .list-box{
        display: flex;
        flex-direction: column;
    }
</style>
<template>
    <div class="product-module">

        <div class="list-box">

            <div class="tab-box">
               <el-table :data="list" v-loading="loading" @row-click="handleRowClick" row-key="getRowKey">
               	<el-table-column width="40px">
               		<template slot-scope="scope">
               			<el-checkbox v-model="scope.row.checked"></el-checkbox>
               		</template>
               	</el-table-column>
               	<el-table-column prop="PageName" label="页面名称" width="860px"></el-table-column>
               </el-table>
            </div>
			
			<div class="pagination" style="margin-top:10px">
			    <el-pagination :current-page="pageIndex" :total="total" :page-size="pageSize" @current-change="pageChange"></el-pagination>
			</div>

        </div>

    </div>
</template>

<script>
import {mallShopDecorateList} from '../../../api/pageManage.js';
export default {
    components: {
    },
    props: {
        
    },
    data () {
        return {
            type: '',
			pageIndex: 1,
			pageSize: 10,
			total: 0,
			list: [],
			loading: false,
        };
    },
    methods: {
        async getData(){
            this.loading = true;
            const params = {
                KeyWord: this.keyword,
                Skip: (this.pageIndex-1)*this.pageSize,
                Take: this.pageSize
            }
            const result = await mallShopDecorateList(params);
            
            this.list = result.Result.Results;
            this.total = result.Result.Total;
            this.loading = false;
        },
		pageChange(value){
		    this.pageIndex = value;
		    this.getData();
		},
		getRowKey(row){
			return row.Id
		},
		handleRowClick(row, event, column){
			console.log(this.list)
			this.list = this.list.map(item=>{
				item.checked = item.Id == row.Id;
				console.log(item.checked)
				return item;
			})
			this.$emit('change', {data:row, type:'custom',text: row.PageName})
		}
        // selectionChange(selection){

        //     const name = (this.list.filter(item=>{
        //         return item.Id == selection;
        //     })[0]).PageName

        //     this.$emit('change', {data: selection, type:'custom', text: '自定义页面>'+name})

        // }
    },
    watch: {
    },
    mounted () {
        
    },
    created () {
        this.getData();
    },
    dispatch () {
        
    }
};
</script>
