<style scoped lang="less">
.brand-module{
    height: 100%;
    display: flex;
    flex-direction: column;

    .search{
        flex: 0 0 auto;
        margin-bottom: 10px;
    }
    .tab-box{
        flex: 1 1 auto;
        margin-bottom: 10px;
    }
    .pagination{
        flex: 0 0 auto;
    }
}
</style>
<template>
    <div class="brand-module">
        
        <div class="search">
        	<span style="padding-right: 10px;">关键字：</span>
            <el-input v-model="keyword" style="width:250px;margin-right:10px" size="mini" placeholder="分组名称"/>
            <el-button type="primary" size="mini" @click="pageIndex=1;getData();">搜索</el-button>
			<el-button size="mini" @click="getData();">刷新</el-button>
        </div>

        <div class="tab-box">
			<el-table :data="data" v-loading="loading" @row-click="handleRowClick" :row-key="getRowKey">
				<el-table-column width="40px">
					<template slot-scope="scope">
						<el-checkbox v-model="scope.row.checked"></el-checkbox>
					</template>
				</el-table-column>
				<el-table-column prop="GroupName" label="分组名称" width="700px"></el-table-column>
				<el-table-column prop="ProductCount" label="关联商品数量" width="200px"></el-table-column>
				
				<div slot="empty">暂无数据，<el-link type="primary" :href="url" target="_blank">点击此处前往添加</el-link></div>
			</el-table>
		</div>

        <div class="pagination">
            <el-pagination :current-page="pageIndex" :total="total" :page-size="pageSize" @current-change="pageChange"></el-pagination>
        </div>

    </div>
</template>
<script>

import {productGroupListData} from '../../../api/goods.js';
export default {
    components: {
        
    },
    props: {
        
    },
    data () {
        return {
            keyword: '',
            pageIndex: 1,
            pageSize: 10,
            total: 0,
            data: [],
            loading: false,
			url: location.origin + '/index.html#/goods/goodGroup'
        };
    },
    methods: {
        async getData(){
            const params = {
                KeyWord: this.keyword,
                Skip: (this.pageIndex-1)*this.pageSize,
                Take: this.pageSize
            }

            this.loading = true;

            const result = await productGroupListData(params);

            this.data = result.Result.Results;
            this.total = result.Result.Total;
            this.loading = false;

        },
        pageChange(value){
            this.pageIndex = value;
            this.getData();
        },
		getRowKey(row){
			return row.Id
		},
		handleRowClick(row, event, column){
			this.data = this.data.map(item=>{
				item.checked = item.Id == row.Id;
				return item;
			})
			this.$emit('change', {data:row, type:'classify', text: row.GroupName})
		}
    },
    watch: {
    },
    mounted () {
        
    },
    created () {
        this.getData();
    },
    dispatch () {
        
    }
};
</script>
