<style scoped lang="less">
    .search-box{
        margin-bottom: 20px;
    }
    .table-box{
        margin-bottom: 15px;
    }
</style>
<template>
    <el-dialog
        :visible="show"
        width='1090px' 
        title="选择链接"
        @close="cancel">

        <el-tabs type="card" @tab-click="tabChange" :value="active">
          <el-tab-pane v-for="tab in list" :key="tab.id" :label="tab.name" :name="tab.key">
						<div style="max-height: 55vh;overflow: auto;">
							<classify-tab v-if="tab.id === 2" @change="record=>dataChange(record, tab.key)"></classify-tab>
							<functional-tab v-if="tab.id === 3" :noservice = "noservice" :unfunctionalTab="unfunctionalTab" :pagelist="pagelist"
							 :isOfficialAccount="isOfficialAccount" @change="record=>dataChange(record, tab.key)"></functional-tab>
							<product-tab v-if="tab.id === 1" @change="record=>dataChange(record, tab.key)"></product-tab>
							<custom-tab v-if="tab.id === 4" @change="record=>dataChange(record, tab.key)"></custom-tab>
							<live-tab v-if="tab.id === 5 && showLiveTab" @change="record=>dataChange(record, tab.key)"></live-tab>
							<truntable v-if="tab.id === 6" @change="record=>dataChange(record, tab.key)"></truntable>
							<coupon v-if="tab.id === 7" @change="record=>dataChange(record, tab.key)"></coupon>
							<help v-if="tab.id === 8" @change="record=>dataChange(record, tab.key)"></help>
							<group v-if="tab.id === 9" @change="record=>dataChange(record, tab.key)"></group>
							<questionnaire v-if="tab.id === 10" @change="record=>dataChange(record, tab.key)"></questionnaire>
							<taketest v-if="tab.id === 11" @change="record=>dataChange(record, tab.key)"></taketest>
							<contentgroup v-if="tab.id === 12" @change="record=>dataChange(record, tab.key)"></contentgroup>
							<singlecontent v-if="tab.id === 13" @change="record=>dataChange(record, tab.key)"></singlecontent>
							<help-pro v-if="tab.id === 14" @change="record=>dataChange(record, tab.key)"></help-pro>



							<!-- <pointsCode v-if="tab.id === 11" @change="record=>dataChange(record, tab.key)"></pointsCode> -->
						</div>
				
          </el-tab-pane>
        </el-tabs>

        
        <div slot="footer">
            <el-button type="primary" @click="btnSure">确定</el-button>
        </div>

    </el-dialog>
</template>
<script>

const tabs = [
	{
	    id: 3,
	    name: '系统页面',
	    key: 'functional'
	},
    {
        id: 2,
        name: '商品分组',
        key: 'classify'
    },
	{
	    id: 1,
	    name: '商品',
	    key: 'product',
	},
    {
        id: 4,
        name: '自定义页面',
        key: 'custom'
    },
	{
		id: 7,
		name: '优惠券',
		key: 'coupon'
	},
	{
		id: 6,
		name: '转盘抽奖',
		key: 'truntable'
	},
	{
		id: 8,
		name: '助力领取商品',
		key: 'help'
	},
	{
		id: 9,
		name: '拼团商品',
		key: 'group'
	},
	{
		id: 10,
		name: '问卷调查',
		key: 'questionnaire'
	},
	{
		id: 11,
		name: '测一测',
		key: 'taketest',
		disposition:true
	},
	{
		id: 12,
		name: '内容分组',
		key: 'contentgroup'
	},
	{
		id: 13,
		name: '单篇内容',
		key: 'singlecontent'
	},
	{
		id: 14,
		name: '助力Pro活动',
		key: 'helpPro',
		disposition:true
	},
]

import productTab from './components/product';
import classifyTab from './components/classify';
import functionalTab from './components/functional';
import customTab from './components/custom';
import liveTab from './components/live';
import truntable from './components/truntable';
import coupon from './components/coupon';
import help from './components/help';
import group from './components/group';
import questionnaire from './components/questionnaire';
import taketest from './components/taketest';
import contentgroup from './components/contentgroup';
import singlecontent from './components/singlecontent';
import helpPro from './components/helpPro';


import pointsCode from './components/pointsCode';
// import videoTab from './components/video';
import {
	mallmallSpecialLimitManagerInit
} from "@/api/api"

export default {
    components: {
			productTab, //产品
			classifyTab, //营销活动
			functionalTab, //功能页面
			customTab, //自定义页面
			// videoTab, //视频模块
			liveTab,//直播模块
			truntable, //转盘抽奖
			coupon, //优惠券
			group,//拼团商品
			help, //助力领取商品
			questionnaire, //问卷调查
			taketest,//测一测
			contentgroup,//内容分组
			singlecontent,//单篇内容
			helpPro,



			// pointsCode,//积分码活动
    },
    props: {
			visible: {
					type: Boolean,
					default: false
			},
			showLiveTab: {
				type: Boolean,
				default: false
					},
			showTab: {
				type: Array,
				default: ()=>{
					return []
				}
			},
			unshowTab: {
				type: Array,
				default: ()=>{
					return []
				}
			},
			unfunctionalTab: { //系统页面 需要展示的数据
				type: Array,
				default: ()=>{
					return []
				}
			},
			isOfficialAccount: {
				type: Boolean,
				default: false
			},
			noservice:{
				type: Boolean,
				default: false
			},
    },
    data () {
        return {
            show: false,
            list: tabs,
            data: {},
            active: 'functional',
						pagelist:[]
        };
    },
    methods: {
        cancel(){
            this.$emit('cancel');
        },
        tabChange(record){
			// console.log(record)
            this.active = record.name;
        },
        dataChange(data, key){
            this.data[key] = data;
        },
        btnSure(){
            if (!this.data[this.active]){
				this.$message({showClose: true,
					type: 'error',
					message: '您未选择任何链接!'
				});
                return false;
            }
			
            this.cancel();
            this.$emit('change', this.data[this.active])
        },
				async getAuthority(){
					try{
						let res = await mallmallSpecialLimitManagerInit()
						if(res.IsSuccess){
							// console.log(res.Result)
							this.pagelist = res.Result.filter(v=>v.Type==1).map(v=>v.RelationId)
							let tablist = res.Result.filter(v=>v.Type==2).map(v=>v.RelationId)
							this.list = this.list.filter(v=>{
								return !v.disposition||(v.disposition&&tablist.some(x=>x==v.id))
							})
						}
					}finally{
						//
					}
				},
    },
    watch: {
        visible(value){
            this.show = value;
        },
    },
    mounted () {
			
			if (this.unshowTab && this.unshowTab.length){
				let list = tabs.filter(item=>{
					return this.unshowTab.indexOf(item.id) == -1
				})
				this.list = list;
			}else{
				let list=[], list1=[], list2=[];
				this.list.map(item=>{
					if (item.id == 5){
						list.push(item);
					}
					if (item.id == 8){
						list1.push(item);
					}
					if (item.id == 9){
						list2.push(item);
					}
				})
				
				if (this.showLiveTab){
					if (!list.length){
						this.list.splice(4, 0, {
							id: 5,
							name: '小程序直播',
							key: 'live'
						})
					}
					if (!list1.length){
						this.list.push({
							id: 8,
							name: '助力领取商品',
							key: 'help'
						})
					}
					if (!list2.length){
						this.list.push({
							id: 9,
							name: '拼团商品',
							key: 'group'
						})
					}
				}else{
					// console.log(this.list)
					if (list.length){
						this.list.splice(4, 1)
					}
					if (list2.length){
						this.list.splice(this.list.length-1, 1)
					}
					if (list1.length){
						this.list.splice(this.list.length-1, 1)
					}
				}
			}
			this.getAuthority()
    },
    created () {
    },
    dispatch () {
        
    }
};
</script>
