<style scoped lang="less">
	.brand-module{
    height: 100%;
    display: flex;
    flex-direction: column;

    .search{
        flex: 0 0 auto;
        margin-bottom: 10px;
    }
    .tab-box{
        flex: 1 1 auto;
        margin-bottom: 10px;
    }
    .pagination{
        flex: 0 0 auto;
    }
}
.shop-name{
	display: flex;
}
.shop-name .img{
	flex: 0 0 auto;
}
.shop-name .name{
	flex: 1 1 auto;
	overflow: hidden;
	display: flex;
	align-items: center;
}
</style>
<template>
	<div class="brand-module">

		<div class="search">
			<span style="padding-right: 10px;">关键字：</span>
			<el-input v-model="keyword" style="width:250px;margin-right:10px" size="mini" placeholder="问卷标题" />
			<el-button type="primary" size="mini" @click="pageIndex=1;getData();">搜索</el-button>
			<el-button size="mini" @click="getData();">刷新</el-button>
		</div>

		<div class="tab-box">
			<el-table :data="data" v-loading="loading" @row-click="handleRowClick" size="mini" row-key="getRowKey">
				<el-table-column width="40px">
					<template slot-scope="scope">
						<el-checkbox v-model="scope.row.checked"></el-checkbox>
					</template>
				</el-table-column>
				<el-table-column label="问卷标题" >
					<template slot-scope="scope">
						<div class="shop-name" style="overflow: hidden;">
							{{scope.row.Title}}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="AddTime" label="创建时间" width="200px"></el-table-column>
				
				<div slot="empty">暂无数据，<el-link type="primary" :href="url" target="_blank">点击此处前往添加</el-link></div>
			</el-table>
		</div>

		<div class="pagination">
			<el-pagination :current-page="pageIndex" :total="total" :page-size="pageSize" @current-change="pageChange"></el-pagination>
		</div>

	</div>
</template>
<script>
	import {
		questionlist
	} from '@/api/TurnTomySelf';
	import config from '@/config/index'

	export default {
		components: {

		},
		props: {

		},
		data() {
			return {
				keyword: '',
				pageIndex: 1,
				pageSize: 10,
				total: 0,
				data: [],
				loading: false,
				imgUrl: config.IMG_BASE,
				url: location.origin + '/index.html#/market/questionnaire/questionlist'
			};
		},
		methods: {
			async getData() {
				const params = {
					Keywords: this.keyword,
					Skip: (this.pageIndex - 1) * this.pageSize,
					Take: this.pageSize
				}

				this.loading = true;

				const result = await questionlist(params);

				this.data = result.Result.Results.map(item=>{
					item.uid = item.Id + '-'+ item.ActivityFullId;
					return item;
				});
				this.total = result.Result.Total;
				this.loading = false;

			},
			pageChange(value) {
				this.pageIndex = value;
				this.getData();
			},
			getRowKey(row) {
				return row.uid
			},
			handleRowClick(row, event, column) {

				this.data = this.data.map(item => {
					item.checked = item.uid == row.uid;
					return item;
				})

				this.$emit('change', {
					data: row,
					type: 'questionnaire',
					text: row.Title
				})
			}
		},
		watch: {},
		mounted() {

		},
		created() {
			this.getData();
		},
		dispatch() {

		}
	};
</script>
