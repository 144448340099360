<template>
  <div class="brand-module">
    <div class="flexRow search">
      <div class="flexRow" style="margin-right:10px">
        <div class="label">关键字：</div>
        <el-input v-model="keywords" style="width:220px" placeholder="活动名称" size="mini"></el-input>
      </div>
      <el-button type="primary" size="mini" @click="currentPage=1,gettablelist()">搜索</el-button>
      <el-button size="mini" @click="gettablelist">刷新</el-button>
    </div>
    <div class="tab-box">
      <el-table :data="tablelist" v-loading="tableloading" @row-click="handleRowClick" row-key="Id">
        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
        <el-table-column width="40px">
          <template slot-scope="scope">
            <el-checkbox :value="scope.row.checked"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="" label="活动名称" min-width="150">
          <template slot-scope="scope">
            <div class="ellipsis">
              {{scope.row.Name}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="活动时间" min-width="180">
          <template slot-scope="scope">
            <div>
              <div v-if="scope.row.IsPermanentActivity">永久有效</div>
              <div v-else>
                <!-- <div> -->
                  {{scope.row.StartTime}}至{{scope.row.EndTime}}
                <!-- </div>
                <div>
                  
                </div> -->
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="IsOpenValue" label="活动状态">
          <template slot-scope="scope">
            <div>
              {{scope.row.State==0?'未开始':(scope.row.State==1?'进行中':'已结束')}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="SendCount" label="兑换码总量"></el-table-column>
        <el-table-column prop="LeaveCount" label="剩余数量"></el-table-column>
        <template slot="empty">
          <div class="flexRow" style="justify-content: center;">
            暂无数据，<el-button type="text" @click="$router.push({path:'/Customer/pointsCode/index'})">点击此处前往添加</el-button>
          </div>
        </template>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pagesize"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  activityPointCodelist
} from "@/api/sv3.0.0"
export default {
  data () {
    return {
      keywords:'',
      tablelist:[],
      tableloading:false,
      currentPage:1,
      pagesize:20,
      total:0,
    }
  },
  mounted () {
    this.gettablelist()
  },
  methods: {
    handleRowClick(row, event, column){
			this.tablelist = this.tablelist.map(item=>{
				item.checked = item.Id == row.Id;
				return item;
			})
			this.$emit('change', {data:row, type:'pointsCode', text: row.Name})
		},
    handleSizeChange(e){
      this.currentPage = 1
      this.pagesize = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let data = {
          KeyWords:this.keywords,
          ActivityState:3,
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize,
        }
        let res = await activityPointCodelist(data)
        if(res.IsSuccess){
          this.tablelist = res.Result.Results.map(v=>{
            v.checked = false
            return v
          })
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.flexRow{
  display: flex;
  align-items: center;
}
.label{
  font-size: 14px;
  color: #606266FF;
  white-space: nowrap;
  flex-shrink: 0;
  // font-weight: bold;
}
.brand-module{
    height: 100%;
    display: flex;
    flex-direction: column;

    .search{
        flex: 0 0 auto;
        margin-bottom: 10px;
    }
    .tab-box{
        flex: 1 1 auto;
        margin-bottom: 10px;
    }
    .pagination{
        flex: 0 0 auto;
    }
}
</style>