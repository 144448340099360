<style scoped lang="less">
.brand-module{
    height: 100%;
    display: flex;
    flex-direction: column;

    .search{
        flex: 0 0 auto;
        margin-bottom: 10px;
    }
    .tab-box{
        flex: 1 1 auto;
        margin-bottom: 10px;
    }
    .pagination{
        flex: 0 0 auto;
    }
}
</style>
<template>
    <div class="brand-module">
        
        <div class="search">
        	<span style="padding-right: 10px;">关键字：</span>
            <el-input v-model="keyword" style="width:200px;margin-right:10px" size="mini" placeholder="直播间Id/直播标题"/>
			<span style="padding-right: 10px;">分组：</span>
			<el-select v-model="groupIds" style="width: 200px;margin-right: 10px;" multiple clearable>
				<el-option label="全部" :value="-1"></el-option>
				<el-option label="未分组" :value="0"></el-option>
				<el-option v-for="item in livegrouplist" :key="item.Value" :label="item.Text" :value="item.Value">
				</el-option>
			</el-select>
            <el-button type="primary" size="mini" @click="pageIndex=1;getData();">搜索</el-button>
						<!-- <el-button type="primary" size="mini" @click="getNewBroad();">获取最新直播列表</el-button> -->
        </div>

        <div class="tab-box">
			<el-table :data="data" size="mini" v-loading="loading" @row-click="handleRowClick" :row-key="getRowKey">
				<el-table-column width="40px">
					<template slot-scope="scope">
						<el-checkbox v-model="scope.row.checked"></el-checkbox>
					</template>
				</el-table-column>
				<el-table-column prop="RoomId" label="直播间ID" width="80px"></el-table-column>
				<el-table-column prop="AnchorNickName" label="主播" width="120px"></el-table-column>
				<el-table-column prop="RoomName" label="直播标题" width="290px"></el-table-column>
				<el-table-column label="直播时间" width="300px">
					<template slot-scope="scope">
						<span>{{scope.row.StartTime}}</span>
						至
						<span>{{scope.row.EndTime}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="StateValue" label="直播状态" width="100px"></el-table-column>
				
				<div slot="empty">暂无数据，<el-link type="primary" :href="url" target="_blank">点击此处前往同步小程序直播间</el-link></div>
				
			</el-table>
		</div>

        <div class="pagination">
            <el-pagination :current-page="pageIndex" :total="total" :page-size="pageSize" @current-change="pageChange"></el-pagination>
        </div>

    </div>
</template>
<script>

import {
		liveRoomList,
		liveRoomRefreshCache
	} from '@/api/goods'
	import {
		liveRoomgroupInit
	} from '@/api/TurnTomySelf'
	
import config from '@/config/index'
export default {
    components: {
        
    },
    props: {
        
    },
    data () {
        return {
            keyword: '',
            pageIndex: 1,
            pageSize: 10,
            total: 0,
            data: [],
            loading: false,
			livegrouplist: [],
			groupIds: [],
			
			url: location.origin + '/index.html#/broadCast/index',
        };
    },
    methods: {
		async getgroupInit() {
			try {
				let result = await liveRoomgroupInit()
				this.livegrouplist = result.Result.GroupSelectList
			} catch (err) {
		
			} finally {
		
			}
		},
        async getData(){
			try {
				this.loading = true
			
				let data = {
					KeyWords: this.keyword, // 搜索关键字
					Skip: (this.pageIndex - 1) * this.pageSize, // 略过数据
					Take: this.pageSize, // 取的数据
					GroupIdList: this.groupIds.includes(-1) ? '' : (this.groupIds.includes(0) ? 0 : this.groupIds),
				}
				let result = await liveRoomList(data);
				this.data = (result.Result.Results || []);
				this.total = result.Result.Total;
				this.loading = false;
			
			} catch (e) {
				//TODO handle the exception
				console.log(e)
			} finally {
				this.loading = false
			}

        },
        pageChange(value){
            this.pageIndex = value;
            this.getData();
        },
		// 获取最新直播
		async getNewBroad() {
			try {
				let result = await liveRoomRefreshCache()
				if (result.IsSuccess) {
					this.$message({
						showClose: true,
						type: 'success',
						message: '操作成功!'
					});
				}
			} catch (e) {
				//TODO handle the exception
				console.log(e)
			} finally {
				this.getData()
			}
		
		},
		getRowKey(row){
			return row.Id
		},
		handleRowClick(row, event, column){
			this.data = this.data.map(item=>{
				item.checked = item.Id == row.Id;
				return item;
			})
			this.$emit('change', {data:row, type:'live', text: row.RoomName})
		},
    },
    watch: {
    },
    mounted () {
        
    },
    created () {
        this.getData();
		this.getgroupInit();
    },
    dispatch () {
        
    }
};
</script>
