<template>
    <div class="product-module">

        <div class="list-box">

            <div class="tab-box">
				<el-table :data="list" @row-click="handleRowClick" :row-key="getRowKey">
					<el-table-column width="40px">
						<template slot-scope="scope">
							<el-checkbox :value="scope.row.checked"></el-checkbox>
						</template>
					</el-table-column>
					<el-table-column prop="name" label="页面名称" width="860px">
						<template slot-scope="scope">
							<div class="name-wraper">
								<div class="name">{{scope.row.name}}</div>
								<div class="tip" v-if="scope.row.id === 7">
									<div v-if="isOfficialAccount">
										（若商城开启微信客服—公众号菜单场景，自定义菜单生成成功后，粉丝点击对应菜单，将自动打开微信客服
										<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccn82KiMlPTpxAW9CjDCd4EFe" style="font-size: 12px;line-height: 20px;margin-bottom: 2px;" target="_blank">什么是微信客服？</el-link>
										）
									</div>
									<div v-else>
										（若商城开启微信客服—小程序商城场景，客户进入商城，点击联系客服，将自动打开微信客服
										<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccn82KiMlPTpxAW9CjDCd4EFe" style="font-size: 12px;line-height: 20px;margin-bottom: 2px;" target="_blank">什么是微信客服？</el-link>
										）
									</div>
								</div>
							</div>
							
						</template>
					</el-table-column>
				</el-table>
            </div>

        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import config from "@/config"
export default {
    components: {
    },
    props: {
			unfunctionalTab: { //系统页面 需要展示的数据
				type: Array,
				default: ()=>{
					return []
				}
			},
			pagelist: {//后台配置的页面权限
				type: Array,
				default: ()=>{
					return []
				}
			},
			isOfficialAccount: {
				type: Boolean,
				default: false
			},
			noservice:{
				type: Boolean,
				default: false
			},
    },
    data () {
        return {
            type: '',
            list: []
        };
    },
    methods: {
			getRowKey(row){
				return row.id
			},
			handleRowClick(row, event, column){
				this.list = this.list.map(item=>{
					item.checked = item.id == row.id;
					return item;
				})
				this.$emit('change', {data:row, type:'functional', text: row.name})
			},

    },
    watch: {
    },
    mounted () {
        
    },
		computed: {
			...mapGetters([
				
			])
		},
    created () {
			let list = []
			// //线上且商户id==643 || 才显示短视频带货
			// let NotShortBandGoods = !config.IsOfficial || (![643,678,103].includes(Number(localStorage.getItem('MallId'))));
			// //ai舌诊
			// let noAi = !config.IsOfficial || (![693,196].includes(Number(localStorage.getItem('MallId'))))
			if(this.noservice){
				list.push(7)
			}
			//微信公众号-自定义页面 才展示门店列表
			if(this.$route.path!='/setting/setMenupage'){
				list.push(20)
			}
			// if(NotShortBandGoods){
			// 	list.push(16)
			// }
			// if(noAi){
			// 	list.push(18)
			// }
			setTimeout(()=>{
				this.list = JSON.parse(JSON.stringify(this.$store.state.pageManageConfig.functionalPageList.filter((v)=>{
					return !list.some(x=>x==v.id)
				})));

				if (this.unfunctionalTab.length){
					this.list = this.list.filter(item=>{
						return this.unfunctionalTab.indexOf(item.id) == -1
					})
				}
				this.list = this.list.filter(v=>{
					return !v.disposition||(v.disposition&&this.pagelist.some(x=>x==v.id))
				})
			// console.log(this.pagelist,this.list)
				// console.log(this.list)
			}, 300)
    },
    dispatch () {
        
    }
};
</script>
<style lang="scss" scoped>
	.name-wraper{
		display: flex;
		
		.tip{
			font-size: 12px;
			color: #999;
			margin-left: 10px;
		}
	}
</style>